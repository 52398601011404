import { BaseClass } from "./base/base.service";

export class ConfigProvider extends BaseClass {
    constructor() {
        super('');
    }

    async getConfig(filter: any) {
        const res = await this.get(`configs?filter=${filter}`);
        return res.data;
    }

    async getGpsConfig() {
        const res = await this.get(`/configs/mobile`);
        return res.data;
    }
    async updateGpsConfig(data: any) {
        const res = await this.put(`/configs/mobile`, data);
        return res.data;
    }
}
export const ConfigService = new ConfigProvider();